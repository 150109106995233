<template>
  <div class="nav">
    <SovaSvg/>
    <div class="nav__menu">
      <HomeSvg
        class="nav__menu__item"
        @click.native="goToHome"
        :class="{'selected' : isActive('Home')}"/>
      <TariffSvg
          class="nav__menu__item"
          @click.native="goToPay"
          :color="!$store.getters.PAY_STATUS ? '#CF6868' : null"
          :class="{'selected' : isActive('Pay')}"/>
      <RecordSvg
          type="setting"
          class="nav__menu__item"
          @click.native="goToArchive"
          :class="{
            'selected' : isActive('Archive'),
            'disabled' : !$store.getters.PAY_STATUS
          }"/>
      <RoomSvg
          class="nav__menu__item"
          @click.native="goToClasses"
          :class="{
            'selected' : isActive('Classes'),
            'disabled' : !$store.getters.PAY_STATUS
          }"/>
      <SettingsSvg
          class="nav__menu__item"
          @click.native="goToProfile"
          :class="{'selected' : isActive('Profile')}"/>
      <FaqSvg
          class="nav__menu__item"
          @click.native="goToFaq"
          :class="{'selected' : isActive('Faq')}"/>
    </div>
    <ExitSvg
      class="nav__exit"
      @click.native="exit"/>
  </div>
</template>

<script>
import ExitSvg from "../../assets/vue-icons/Settings/ExitSvg";
import HomeSvg from "@/assets/vue-icons/Settings/HomeSvg";
import TariffSvg from "@/assets/vue-icons/Settings/TariffSvg";
import RecordSvg from "@/assets/vue-icons/Settings/RecordSvg";
import RoomSvg from "@/assets/vue-icons/Settings/RoomSvg";
import SettingsSvg from "@/assets/vue-icons/Settings/SettingsSvg";
import FaqSvg from "@/assets/vue-icons/Settings/FaqSvg";
import SovaSvg from "@/assets/vue-icons/Settings/SovaSvg";
export default {
  name: "DesktopNavBar",
  components: {SovaSvg, FaqSvg, SettingsSvg, RoomSvg, RecordSvg, TariffSvg, HomeSvg, ExitSvg},
  props:{
    activeElement: 'Home' | 'Pay' | 'Profile' | 'Archive' | 'Classes' | 'Faq'
  },
  computed:{
    profile(){
      return this.$store.getters.PROFILE
    },

  },
  methods:{
    isActive(elementName){
      return elementName === this.activeElement
    },
    async goToHome(){
      await this.$router.push({name:"ParentDashboard"})
    },

    async goToPay(){
      await this.$router.push({name:"ParentPay"})
    },

    async goToProfile(){
      await this.$router.push({name:"SettingsProfileView"})
    },

    async goToClasses(){
      await this.$router.push({name:"SettingsClassesView"})
    },

    async goToFaq(){
      await this.$router.push({name:"SettingsFaqView"})
    },

    async goToArchive(){
      await this.$router.push({name:"ArchiveDesktopListView"})
    },


    async exit(){
      await this.$store.dispatch("RESET_STATE_TO_DEFAULT")
      await this.$store.dispatch("EXIT_FROM_ACCOUNT");

      // await this.$router.push({name:"NewAuth"})
      document.location.href = '/auth'
    }
  },
  created() {
    if (this.$route.name != 'ParentPay'){
      this.$store.commit("SET_COLOR_TYPE",1)
    } else {
      this.$store.commit("SET_COLOR_TYPE",Math.floor(Math.random() * 4) + 2)
    }
  },
  updated() {
    if (this.$route.name != 'ParentPay' && this.$store.getters.COLOR_TYPE !== 1){
      this.$store.commit("SET_COLOR_TYPE",1)
    } else if (this.$route.name === 'ParentPay' && this.$store.getters.COLOR_TYPE === 1){
      this.$store.commit("SET_COLOR_TYPE",Math.floor(Math.random() * 4) + 2)
    }
  }
}
</script>

<style scoped lang="scss">
  .nav{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #EBEDEE;
    position: fixed;
    align-items: center;
    z-index: 999;

    &__menu{
      width: 342px;
      display: flex;

      &__item{
        margin-right: 30px;
        cursor: pointer;


      }

      &__item:last-child{
        margin-right: 0px;
      }
    }

    &__exit{
      margin-left: 88px;
      margin-right: 20px;
    }
  }
</style>
