
















































































































































import IMask from "imask";
import {Component, Vue} from "vue-property-decorator";
import MaterialButton from "../button/MaterialButton.vue";
import MaterialInput from "../input/MaterialInput.vue";
import AlertText from "../alert/AlertText.vue";
import {School} from "@/entity/School";

@Component({
    components: {AlertText, MaterialInput, MaterialButton}
})
export default class InterviewModal extends Vue{
    private privates:any= {}
    private carousel= {}
    private position= 0
    private isAlert= 0
    private alertText = ""
    private email= ""
    private phone= ""
    private login= ""
    private password= ""
    private repeatPassword= ""
    private lastName= ""
    private firstName= ""
    private parentStatus = ""
    private profileSchool: School | any = {}
    private cls= {}


    get isMobile(){
        return screen.width < 992
    }

    get width(){
        return (this.isMobile) ? '350px' : '620px'
    }

    get schoolAvatar(){
        if (this.profileSchool.avatar === null)
            return ""
        return this.profileSchool.avatar
    }

    onBeforeOpen(e: any){
        this.profileSchool = e.params.profileSchool
        this.cls = e.params.class
    }

    next1step(){
        if(this.email.length === 0 || !this.isValidEmail(this.email)){
            this.alertText = "Пидр, Email нужен"
            this.isAlert = 1
        } else if (!this.isValidPhone(this.phone)){
            this.alertText = "Пидр, телефон нужен"
            this.isAlert = 1
        }else {
            this.isAlert = 0
            this.next()
        }
    }

    async next2step(){
        if (this.login.length === 0){
            this.alertText = "Пидр, Логин нужен"
            this.isAlert = 2
        } else if (this.password.length < 6){
            this.alertText = "Пидр, пароль у тебя маленький"
            this.isAlert = 2
        } else if (this.password !== this.repeatPassword){
            this.alertText = "Пидр, пароль и повторите пароль"
            this.isAlert = 2
        } else {
            await this.$store.dispatch("CHECK_LOGIN_EXISTS",{login: this.login});
            if (this.$store.getters.IS_EXISTS_LOGIN){
                this.alertText = "Пидр, такой человек уже есть"
                this.isAlert = 2
            } else {
                this.isAlert = 0
                this.next()
            }
        }
    }

    skip2step(){
        this.login = "";
        this.password = "";
        this.repeatPassword = ""
        this.isAlert = 0
        this.next()
    }

    finalStep(){
        if (this.firstName.length === 0){
            this.alertText = "Пидр, Имя нужен"
            this.isAlert = 3
        } else if (this.lastName.length === 0){
            this.alertText = "Пидр, фамилия нужна"
            this.isAlert = 3
        } else if (this.parentStatus == ""){
            this.alertText = "кто ты по жизни"
            this.isAlert = 3
        } else {
            this.close();
            this.$emit("update",this.saveInformation())
        }
    }


    isValidEmail(email : string){
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidPhone(phone : string){
        return phone.length === 16
    }

    opened () {
        this.initCarousel({
            'main': '.js-carousel',
            'wrap': '.js-carousel__wrap',
            'prev': '.js-carousel__prev',
            'next': '.js-carousel__next'
        })

        const maskOptions = {
            mask: '+{7}(000)000-00-00'
        }
        //@ts-ignore
        this.mask = new IMask(document.getElementById("phone"), maskOptions)
    }

    initCarousel(setting : any) {
        this.privates = {}

        /* Privates properties */
        this.privates.setting = setting

        this.privates.sel = {
            'main': document.querySelector(this.privates.setting.main),
            'wrap': document.querySelector(this.privates.setting.wrap),
            'prev': document.querySelector(this.privates.setting.prev),
            'next': document.querySelector(this.privates.setting.next)
        }

        this.privates.opt = {
            'position': 0,
            'max_position': 3
        }
    }

    next() {
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
        window.scrollTo(0,0)
        ++this.privates.opt.position
        ++this.position

        if (this.privates.opt.position >= this.privates.opt.max_position) {
            this.privates.opt.position = 0
        }

        // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
        // setTimeout(() => this.privates.sel.wrap.children[this.privates.opt.position].classList.remove('hidden'), 200)

        this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")

    }

    previous() {
        this.isAlert = 0
        this.login = ""
        this.password = ""
        this.repeatPassword = ""
        this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-in-right")
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
        --this.privates.opt.position
        --this.position

        if (this.privates.opt.position < 0) {
            this.privates.sel.wrap.classList.add('s-notransition')
            this.privates.opt.position = this.privates.opt.max_position - 1
        }

        // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
        this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
        this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")
    }


    async exit(){
      await this.$store.dispatch("EXIT_FROM_ACCOUNT");

        await this.$router.push({name: "NewAuth"})
    }

    close(){
        this.$modal.hide("InterviewModal")
    }

    saveInformation(){
        const dataObject = {
            firstName: this.firstName,
            lastName: this.lastName,
            parentStatus : this.parentStatus,
            password : this.password,
            login : this.login,
            phone : this.phone,
            email : this.email
        }

        return dataObject;
    }
}
