<template>
    <div class="left-side-bar--container">
        <div class="profile--container">
            <div class="avatar--container">
                <img class="avatar__img" src="https://gutta.lv/wp-content/uploads/2015/10/test-img.jpg">
            </div>
            <div class="text--container">
                <p class="name__text">{{fullName}}</p>
                <p class="title__text" v-if="stringClasses == null"></p>
                <p class="title__text" v-else>ученики | {{ stringClasses}}</p>
            </div>
        </div>
        <div class="item--list-container">
            <div class="main--list">
                <div class="menu--list-item home" :class="{'active': isActive('Home')}" @click="goToHome"><span class="icon-home"></span><a>Главная</a></div>
                <div class="menu--list-item pay" :class="{'active': isActive('Pay')}" @click="goToPay"><img v-if="!isPaid" class="notification__img" src="../../assets/notif.svg"><span class="icon-pay"></span><a>Подписки</a></div>
                <div class="menu--list-item profile" :class="{'active': isActive('Classes')}" @click="goToClasses"><span class="icon-classes"></span><a>Кабинет</a></div>
                <div class="menu--list-item settings" :class="{'active': isActive('Profile')}" @click="goToProfile"><span class="icon-settings-new"></span><a>Настройки</a></div>
              <div class="menu--list-item faq" :class="{'active': isActive('Faq')}" @click="goToFaq">
                <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Side-Bar-23232323" transform="translate(-35.000000, -436.000000)" fill="#FFFFFF" fill-rule="nonzero">
                      <g id="Group-2" transform="translate(35.000000, 435.600000)">
                        <g id="Group" transform="translate(0.000000, 0.400000)">
                          <path d="M10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,7.3478351 18.9464316,4.80429597 17.0710678,2.92893219 C15.195704,1.0535684 12.6521649,0 10,0 Z M10.988,14.722 L9.1,14.722 L9.1,12.87 L11.014,12.87 L10.988,14.722 Z M12.809,9.382 C12.5402573,9.71001817 12.2288266,10.0005971 11.883,10.246 L11.42,10.616 C11.1877371,10.7844626 11.0148006,11.0221627 10.926,11.295 C10.8621576,11.5367598 10.8308812,11.7859617 10.833,12.036 L9.133,12.036 C9.13028821,11.5342922 9.20310378,11.0350337 9.349,10.555 C9.55289204,10.1690837 9.84986326,9.84014681 10.213,9.598 L10.676,9.228 C10.8183858,9.1253167 10.9433167,9.00038578 11.046,8.858 C11.2085032,8.63356613 11.2950445,8.36308062 11.293,8.086 C11.3007494,7.76374 11.2031438,7.44775077 11.015,7.186 C10.7641008,6.88994572 10.3812959,6.73967883 9.996,6.786 C9.58252609,6.74770668 9.18085025,6.93686575 8.947,7.28 C8.7481057,7.58286291 8.64081524,7.93667837 8.638,8.299 L6.79,8.299 C6.73028199,7.28166031 7.21097591,6.30811262 8.055,5.737 C8.61064667,5.39454728 9.25461353,5.22277642 9.907,5.243 C10.7513116,5.21135843 11.5837954,5.44916092 12.284,5.922 C12.9291318,6.39367625 13.2897771,7.1613194 13.241,7.959 C13.256754,8.4669182 13.1049482,8.96590953 12.809,9.379 L12.809,9.382 Z" id="Compound_Path"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <a>FAQ</a>
              </div>

              <!--                <div class="menu&#45;&#45;list-item profile" :class="{'active': isActive('Faq')}" @click="goToFaq"><span class="icon-profile"></span><a>FAQ</a></div>-->
            </div>
            <div class="sub--list">
                <div class="menu--list-item exit" @click="exit"><span class="icon-exit-new"></span><a>Выход</a></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LeftSideBar",
        props:{
            activeElement: 'Home' | 'Pay' | 'Profile' | 'Classes' | 'Faq'
        },
        computed:{
            isPaid(){
                return this.$store.getters.PAY_STATUS
            },

            profile(){
                return this.$store.getters.PROFILE
            },


            fullName(){
                if (this.profile == null){
                    return ""
                }

                return this.profile.fullName
            },


          stringClasses(){
            let str= ""
            for(let cl of this.profile.myClasses){
              if (str === ""){
                str+= (cl.type === 0) ? cl.fullName : cl.fullName+"гр"
                continue
              }

              str+=", "
              str+= (cl.type === 0) ? cl.fullName : cl.fullName+"гр"
            }
            return str
          }
        },
        methods:{

            isActive(elementName){
                return elementName === this.activeElement
            },
            async goToHome(){
                await this.$router.push({name:"ParentDashboard"})
            },

            async goToPay(){
                await this.$router.push({name:"ParentPay"})
            },

            async goToProfile(){
                await this.$router.push({name:"SettingsProfileView"})
            },

            async goToClasses(){
                await this.$router.push({name:"SettingsClassesView"})
            },

            async goToFaq(){
                await this.$router.push({name:"SettingsFaqView"})
            },


            async exit(){
                await this.$store.dispatch("RESET_STATE_TO_DEFAULT")
              await this.$store.dispatch("EXIT_FROM_ACCOUNT");

                // await this.$router.push({name:"NewAuth"})
                document.location.href = '/auth'
            }
        }
    }
</script>

<style scoped lang="scss">
    .left-side-bar--container{
        display: none;
        background-color: $navbarColor;
        height: 100%;
        flex-direction: column;

        @include for-big-desktop-up{
            width: 220px;
            min-width: 220px;
        }

        @include for-desktop-up{
            width: 80px;
            min-width: 80px;
        }

        @include for-desktop-devices{
            display: flex;
        }


        .profile--container{
            margin-top: 32px;
            display: flex;
            width: 196px;
            height: 80px;
            margin-left: auto;
            margin-right: auto;

            @include for-desktop-up{
                margin-top: 25.6px;
                width: 164px;
                height: 65px;
            }

            @include for-big-desktop-up{
                margin-top: 25.6px;
                width: 164px;
                height: 65px;
            }

            .avatar--container{
                display: none;
                .avatar__img{
                    object-fit: cover;
                    border-radius: 50%;

                    @include for-desktop-up{
                        width: 64px;
                        height: 64px;
                        border: 3px solid white;
                        margin-left: 8px;
                    }

                    @include for-big-desktop-up{
                        width: 58px;
                        height: 58px;
                        border: 3px solid white;
                    }
                }


            }

            .text--container{

                @include for-desktop-up{
                    display: none;
                }
                .name__text{
                    margin-top: 15px;
                    color: white;
                    font-family: Roboto, "sans-serif";
                    font-size: 15px;
                    font-weight: 500;
                    pointer-events: none;
                }

                .title__text{
                    margin-top: 6px;
                    color: white;
                    font-family: Roboto, "sans-serif";
                    font-size: 12px;
                    font-weight: 300;
                    pointer-events: none;
                }
            }

        }

        .item--list-container{
            height: calc(100% - 65px - 32px - 26px);
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .main--list{
                display: flex;
                flex-direction: column;

                .menu--list-item{
                    cursor: pointer;
                    height: 91px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;


                    @include for-desktop-up{
                        height: 74px;
                    }

                    @include for-big-desktop-up{
                        height: 74px;
                    }

                    &:hover{
                        background-color: $navbarColor;
                        filter: brightness(87.5%);
                    }

                    &.active{
                        background-color: $navBarSelectedItem;
                        filter: brightness(100%);
                    }

                    &.home{
                        span{
                            font-size: 18px;
                        }
                    }

                    &.pay{
                        span{
                            margin-left: 32px;
                            font-size: 14px;
                            a{

                            }
                        }
                        a{
                            margin-left: 2px;
                        }
                    }

                    &.profile{
                        span{
                            font-size: 18px;

                        }
                    }


                  &.faq{
                    svg{
                      margin-left: 28px;

                      @include for-desktop-up{
                        margin-right: 0;
                      }

                      @include for-big-desktop-up{
                        margin-right: 36px;
                      }
                    }
                  }

                    span{
                        @include for-desktop-up{
                            width: 51px;
                            margin-left: 34px;

                            &.icon-pay{
                                margin-left: 30px;

                            }
                        }

                        @include for-big-desktop-up{
                            width: 51px;
                            margin-left: 34px;

                        }

                        &:before{
                            color: white;
                        }
                    }

                    a{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        color: white;

                        @include for-desktop-up{
                            display: none;
                        }

                        @include for-big-desktop-up{
                            font-family: Arial, "sans-serif";
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }

            }

            .sub--list{
                display: flex;
                flex-direction: column;
                margin-bottom: 25px;
                .menu--list-item{
                    cursor: pointer;
                    height: 91px;
                    width: 100%;
                    display: flex;
                    align-items: center;


                    &.exit{

                        span{
                            font-size: 25px;
                            margin-left: 30px;
                        }
                    }


                    @include for-desktop-up{
                        height: 74px;
                    }

                    @include for-big-desktop-up{
                        height: 74px;
                    }

                    &:hover{
                        background-color: $navbarColor;
                        filter: brightness(87.5%);
                    }

                    &.active{
                        background-color: $navBarSelectedItem;
                        filter: brightness(100%);
                    }


                    span{
                        @include for-desktop-up{
                            width: 51px;
                            margin-left: 34px;

                            &.icon-pay{
                                margin-left: 30px;

                            }
                        }

                        @include for-big-desktop-up{
                            width: 51px;
                            margin-left: 34px;

                        }

                        &:before{
                            color: white;
                        }
                    }

                    a{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        color: white;

                        @include for-desktop-up{
                            display: none;
                        }

                        @include for-big-desktop-up{
                            font-family: Arial, "sans-serif";
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }


            }

        }
    }

    .notification__img{
        position: absolute;
        left: 50px;
        top: 20px;
        opacity: .9;
    }


    *{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }


    .icon-classes{
        margin-left: 27px !important;
    }

    .icon-settings-new{
        margin-left: 30px !important;
    }

    .menu--list-item.profile{
        a{
            margin-left: 6px;
        }
    }

    .menu--list-item.settings{
        a{
            margin-left: 4px;
        }
    }
</style>
