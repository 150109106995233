<template>
    <div class="group">
        <input v-if='mutType === "tel"' :type="mutType" :id="id" onfocus="this.removeAttribute('readonly');" autocorrect="off" spellcheck="false" :autocomplete="generateString(9)" data-lpignore="true"
               :value="value" @input="changeValue($event.target.value,$event)" @paste="onPaste" required v-mask="'+7(###)###-##-##'">
      <input v-else :type="mutType" :id="id" onfocus="this.removeAttribute('readonly');" autocorrect="off" spellcheck="false" :autocomplete="generateString(9)" data-lpignore="true"
             :value="value" @input="changeValue($event.target.value,$event)"  @paste="onPaste" required>
        <span class="highlight"></span>
        <span class="bar"></span>
        <label :for="id">{{label}}</label>
        <a v-if="iconClass && !isShowPassword" @click="toggleShowPassword"><span :class="iconClass"></span></a>
        <a v-if="iconClass === 'icon-hide' && isShowPassword" @click="toggleShowPassword"><span class="icon-show"></span></a>

    </div>
</template>

<script>
import { mask } from 'vue-the-mask'


    export default {
        name: "MaterialInput",
        props:{
            type: String,
            id: String,
            label: String,
            iconClass: String,
            value: String,
            isTrimEnable: Boolean,
            isRussianSymbolsDisabled: Boolean
        },
      directives: {
        mask
      },
        watch:{
            type:{
                immediate:true,
                deep: true,
                handler(newVal){
                    this.mutType = newVal
                }
            }
        },
        data(){
            return {
                isShowPassword: false,
                mutType : 'input'
            }
        },
        methods:{
            changeValue(value,ev){
                if (this.isTrimEnable){
                    value = value.replace(/ /g,'')
                }
                if (this.isRussianSymbolsDisabled){
                    value = value.replace(/[^a-zA-Z0-9]+/gm,'')
                }
                ev.target.value = value

                this.$emit('input',value)
            },
            onPaste(e){
              // Stop data actually being pasted into div
              e.stopPropagation();
              e.preventDefault();

              // Get pasted data via clipboard API
              const clipboardData = e.clipboardData || window.clipboardData;
              const pastedData = clipboardData.getData('Text').trim();
              this.changeValue(pastedData,e)
            },


            toggleShowPassword(e){
                console.log(e)
                if (e.target.classList.contains('icon-show') || e.target.classList.contains('icon-hide')){
                    this.isShowPassword = !this.isShowPassword
                    if (this.isShowPassword){
                        this.mutType = 'input'
                    } else {
                        this.mutType = 'password'
                    }
                }
            },

            generateString(length){
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }
        }
    }
</script>

<style scoped lang="scss">
    $bg-color: #424242;
    $hl-color: #F54E5E;
    $muted-color: mix(white, $bg-color, 70%);
    $trans-time: 300ms;
    $width: 280px;

    .group {
        position: relative;
        width: $width;
    }

    input,
    textarea {
        background: none;
        color: black;
        font-family: Roboto, "sans-serif";
        font-weight: 300;
        font-size: 14px;
        padding: 16px 10px 12px 0px;
        display: block;
        width: 280px;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #c6c6c6;
        letter-spacing: 0.8px;
        height: 43px;
        &:focus {
            outline: none;
        }
        &:focus ~ label,
        &:valid ~ label {
            top: -10px;
            font-size: 12px;
            color: $hl-color;
        }
        &:focus ~ .bar:before {
            width: $width;
        }
    }

    input[type="password"] {
        letter-spacing: 0.3em;
    }

    label {
        color: $muted-color;
        font-size: 12px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 0px;
        top: 15px;
        transition: $trans-time ease all;
    }

    .bar {
        position: relative;
        display: block;
        width: $width;
        &:before {
            content: '';
            height: 2px;
            width: 0;
            bottom: 0px;
            position: absolute;
            background: $hl-color;
            transition: $trans-time ease all;
            left: 0%;
        }
    }


    a{
        position: absolute;
        right: 0;
        top: 13px;

        span{
            opacity: .4;

            &.icon-hide{
                font-size: 12px;
                cursor: pointer;
            }

            &.icon-user{
                font-size: 12px;
            }
        }

        span:before{
            color: #141213;
        }

    }

    input[type="password"] {
        @include for-mobile-devices{
            font:small-caption;
            font-size:10px;
        }
    }


    .icon-show{
        font-size: 9px;
        cursor: pointer;
    }

</style>
