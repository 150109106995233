<template>
  <svg
      width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Fill 6</title>
    <defs>
      <linearGradient x1="100%" y1="108.217174%" x2="0%" y2="0%" id="linearGradient-1">
        <stop stop-color="#E83F94" offset="0%"></stop>
        <stop stop-color="#F54E5E" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
      <g id="icons/-иконка-профиль" fill="url(#linearGradient-1)"
         ref="svg"
         v-touch:start="onTouchStart"
         v-touch:end="onTouchStop">
        <path d="M0,3.33305502 L0,26.66611 C0,28.4996243 1.48284211,30 3.33305502,30 L26.66611,30 C28.4996243,30 30,28.4996243 30,26.66611 L30,3.33305502 C30,1.49954079 28.4996243,0 26.66611,0 L3.33305502,0 C1.48284211,0 0,1.49954079 0,3.33305502 L0,3.33305502 Z M20,10 C20,12.7661351 17.7665526,14.9995825 14.9995825,14.9995825 C12.2334474,14.9995825 10,12.7661351 10,10 C10,7.23302997 12.2334474,4.99958253 14.9995825,4.99958253 C17.7665526,4.99958253 20,7.23302997 20,10 L20,10 Z M4.99958253,23.333055 C4.99958253,20 11.6665275,18.1664858 14.9995825,18.1664858 C18.3334725,18.1664858 24.9995825,20 24.9995825,23.333055 L24.9995825,24.9995825 L4.99958253,24.9995825 L4.99958253,23.333055 L4.99958253,23.333055 Z" id="Fill-6"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ProfileSvg",
  methods:{
    onTouchStart(){
      this.$refs.svg.style.opacity = .8
    },
    onTouchStop(){
      this.$refs.svg.style.opacity = 1
    }
  }
}
</script>

<style scoped>

</style>
