<template>
  <div class="navigation--view"
       v-show="!$store.getters.IS_LOADING && !isFullscreen ">
    <div class="name--container">
      <p class="name__text">{{account.fullName}}</p>
      <div class="classes">
        <ClassIcon
            v-for="cl of account.myClasses"
            v-bind:key="cl.id"
            class="classes__icon"
            :text="cl.fullName"/>
      </div>
    </div>
    <div class="exit--container">
      <ProfileSvg
          v-if="$windowWidth < 992"
          class="exit__button-img"
          @click.native="toogle"/>
    </div>
  </div>
</template>

<script>
import ProfileSvg from "../../assets/vue-icons/Main/ProfileSvg";
import ClassIcon from "../../assets/vue-icons/Class/ClassIcon";
export default {
  name: "MobileNavBar",
  components: {ClassIcon, ProfileSvg},
  props:{
    account: Object
  },
  data(){
    return {

    }
  },
  computed:{
    isFullscreen(){
      const isFullscreen = this.$store.getters.IS_FULLSCREEN_CAMERA || this.$store.getters.IS_FULLSCREEN_SCHEDULE
      try{
        const chatraContainer = document.getElementsByClassName("chatra--webkit")[0];
        console.log(chatraContainer)
        if (isFullscreen){
          //@ts-ignore
          chatraContainer.style.display = 'none'
        } else {
          //@ts-ignore
          chatraContainer.style.display = 'block'
        }
      } catch (e){
        console.error(e)
        return isFullscreen
      }

      return isFullscreen
    }

  },
  methods:{
    toogle(){
      this.$router.push({name : 'SettingsMainListView'})
    },
    async exit(){
      const res = await this.$store.dispatch("EXIT_FROM_ACCOUNT");
      if (res){
        await this.$store.dispatch("RESET_STATE_TO_DEFAULT")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        this.$router.push({name: "AuthView"})

        // document.location.href = '/auth'

      }
    },
  }
}
</script>

<style scoped lang="scss">
.navigation--view{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;

  .name--container{
    margin-left: 0px;

    @include media(992){
      margin-left: 20px;
    }

    //p{
    //    font-size: 16px;
    //    font-family: Roboto, "sans-serif";
    //    letter-spacing: 0;
    //    line-height: 19px;
    //}
    //
    //.class__text{
    //    font-size: 15px;
    //}
    //
    //.class__text{
    //    opacity: 0.6;
    //}


    .name__text{
      font-family: CodeNext;
      font-weight: bold;
      font-size: 16px;
      color: #333942;
      text-align: left;
      line-height: 24px;
    }
  }

  .exit--container{
    margin-right: 20px;
    position: relative;

    .exit__button-img{
    }

    .exit--dropdown-container{
      position: absolute;
      width: 180px;
      height: 0px;
      overflow: hidden;
      pointer-events: none;
      background: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.20);
      right: -19px;
      transition: opacity 0.3s;
      top: 29px;
      opacity: 0;



      a{
        display: flex;
        height: 19px;
        margin-left: 18px;
        margin-top: 15px;

        font-size: 14px;
        font-family: Roboto, "sans-serif";
        line-height: 19px;
        letter-spacing: 0;


        img{
          margin-right: 13px;
        }
      }


      &.open{
        height: 52px;
        pointer-events: auto;
        z-index: 99999;
        opacity: 1;
      }
    }
  }
}

.classes{
  display: flex;

  &__icon{
    margin-right: 5px;


      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
}
</style>
