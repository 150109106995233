<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Профиль/инструменты/для десктопа/Home </title>
    <g id="Профиль/инструменты/для-десктопа/Home-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M25.3125,14.875 L23.5,13.244875 L23.5,9.25 C23.5,8.63125 22.9375,8.125 22.25,8.125 L21,8.125 C20.3125,8.125 19.75,8.63125 19.75,9.25 L19.75,9.872125 L17.25,7.624375 C16.90875,7.334125 16.59625,7 16,7 C15.40375,7 15.09125,7.334125 14.75,7.624375 L6.6875,14.875 C6.2975,15.240625 6,15.50725 6,16 C6,16.633375 6.54,17.125 7.25,17.125 L8.5,17.125 L8.5,23.875 C8.5,24.49375 9.0625,25 9.75,25 L13.5,25 L13.5,19.375 C13.5,18.75625 14.0625,18.25 14.75,18.25 L17.25,18.25 C17.9375,18.25 18.5,18.75625 18.5,19.375 L18.5,25 L22.25,25 C22.9375,25 23.5,24.49375 23.5,23.875 L23.5,17.125 L24.75,17.125 C25.46,17.125 26,16.633375 26,16 C26,15.50725 25.7025,15.240625 25.3125,14.875 Z" id="Path" fill="#788396" fill-rule="nonzero"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HomeSvg"
}
</script>

<style scoped lang="scss">
  .selected{
    g{
      path{
        fill: #333942;
      }
    }

    &:hover{
      g{
        path{
          fill: #333942;
        }
      }
    }
  }

  svg:hover{
    g{
      path{
        fill: #6E7889;
      }
    }
  }
</style>
