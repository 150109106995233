











import {Component, Vue, Watch} from "vue-property-decorator";
import InterviewModal from "@/components/modal/InterviewModal.vue";
import BottomNavigationBar from "@/components/NavBar/BottomNavigationBar.vue";
import LeftSideBar from "@/components/NavBar/LeftSideBar.vue";
import {School} from "@/entity/School";
import {NeedMoreInformationException} from "@/entity/exception/NeedMoreInformationException";
import DesktopNavBar from "@/components/NavBar/DesktopNavBar.vue";
import MobileNavBar from "@/components/NavBar/MobileNavBar.vue";

@Component({
    components: {MobileNavBar, DesktopNavBar, LeftSideBar, BottomNavigationBar, InterviewModal},
})
export default class Main extends Vue{
    private activeElement= ''
    private mobileKeyboardIsOpen= false



  get isFullscreen(){
      const isFullscreen = this.$store.getters.IS_FULLSCREEN_CAMERA || this.$store.getters.IS_FULLSCREEN_SCHEDULE
    try{
      const chatraContainer = document.getElementsByClassName("chatra--webkit")[0];
      console.log(chatraContainer)
      if (isFullscreen){
        //@ts-ignore
        chatraContainer.style.display = 'none'
      } else {
        //@ts-ignore
        chatraContainer.style.display = 'block'
      }
    } catch (e){
        console.error(e)
        return isFullscreen
    }

    return isFullscreen
  }


    @Watch("$route")
    onRouteChange(to : any,from : any){
      this.activeElement = this.getActiveElementName(to.name)
    }

    get isMobile(){
        return screen.width < 992
    }



    get isNeedShow(){
        return ![
            'SettingsClassesView',
            'SettingsProfileView',
            'SettingsClassesAddView',
            'SettingsFaqView',
        ].includes(typeof this.$route.name === "string" ? this.$route.name : '')
    }


  getActiveElementName(routeName : any){
    switch (routeName) {
      case 'ParentDashboard':
        return 'Home'
      case 'ParentPay':
        return 'Pay'
      case 'SettingsMainListView':
      case 'SettingsProfileView':
        return 'Profile'
      case 'ArchiveDesktopListView':
        return 'Archive'
      case 'SettingsClassesView':
      case 'SettingsClassesAddView':
        return 'Classes'
      case 'SettingsFaqView':
        return 'Faq'
      default:
        return ''
    }
  }

    async created(){
        //
        // if (screen.orientation.lock("portrait")){
        //     console.log("yes")
        // } else {
        //     console.log("no")
        // }
        await this.init()
    }




    async init(){
        try{
            await this.$store.dispatch("GET_PROFILE")
            const profile = this.$store.getters.PROFILE
            await this.$store.dispatch("GET_SCHOOL",{ id : profile._schoolId})
          if (profile._payStatus === 0){
            await this.$router.push({name: "ParentPay"})
          }

          this.activeElement = this.getActiveElementName(this.$route.name)


          const profileSchool = this.$store.getters.SCHOOL

          let schArray = []
          for(let schoolClass of profile.myClasses){
            schArray.push(schoolClass.fullName)
          }

          // @ts-ignore
          Chatra('setIntegrationData', {
            /* main properties */
            name: profile._fullName,
            email: profile._email,
            phone: profile._phone,
            userId : profile._id,
            "Классы" : schArray.join(","),
            "Учреждение" : profileSchool.name,
            "Version" : "1.3.1"
          });




           // throw new NeedMoreInformationException(profile._schoolClass,profile)
        } catch (e) {
            if (e instanceof NeedMoreInformationException){
                this.$router.push({name: "InterviewView"})
            }
        }
    }
}
